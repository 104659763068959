/**
 * ET – EntiTy
 * FD – FielD
 * FM – ForM
 * PG – PaGe
 * ST – StaTe
 * AN – ActioN
 * TM – TerM
 * PL – PluraLization
 * CT - ComponenT
 */
export default {
  CT: {
    's-confirm-clicker': {
      clickNumber: 'Натисніть ще {n} раз | Натисніть ще {n} рази | Натисніть ще {n} разів'
    }
  },
  ET: {
    accounting_invoice: {
      1: 'Рахунок фактури',
      2: 'Рахунки фактури',
      plural_not_found: 'Не знайдено',
      FD: {
        id: 'ID',
        task_id: 'ID Заявки',
        counterparty: 'Контрагент',
        account_number: '№ рахунку',
        name: 'Найменування',
        amount: 'К-сть',
        price: 'Ціна без ПДВ',
        sum: 'Сума без ПДВ',
        operation_code: 'Код роботи',
        responsible: 'Відповідальний',
        created_at: 'Створено',
        updated_at: 'Оновлено',
      },
      FM: {
        import: 'Імпорт даних',
      },
      HT: {
        import: 'Імпортування даних за допомогою файлу Excel',
      },
      AN: {
        import: 'Імпортувати',
      }
    },
    accounting_material: {
      1: 'Рахунок матеріал',
      2: 'Рахунки матеріалів',
      plural_not_found: 'Не знайдено',
      FD: {
        accounting_material_id: 'ID',
        accounting_invoice_id: 'ID рахунку',
        material_id: 'ID матеріалу',
        material: '@:ET.material.1',
        counterparty: 'Контрагент',
        account_number: '№ рахунку',
        name: 'Найменування',
        amount: 'К-сть',
        used: 'Використано',
        status: 'Статус',
        responsible: 'Відповідальний',
        used_at: 'Час повного списання',
        created_at: 'Створено',
        updated_at: 'Оновлено',
      },
      status: {
        PENDING: 'Очікує поповнення',
        REGISTERED: 'Зареєстровано',
        SPENT: 'Використано',
      },
      AN: {
        inventory: 'Перенести на склад',
      },
      FM: {
        inventory: 'Внесення матеріалів',
      },
    },
    accounting_material_transaction: {
      FD: {
        created_at: 'Дата списання з рахунку',
      }
    },
    task: {
      1: 'Заявка',
      2: 'Заявки',
      PL: 'Заявка | Заявки | Заявок',
      id: '#{task_id}',
      with_id: 'Заявка @:ET.task.id',
      FD: {
        client: '@:ET.client.1',
        object: '@:ET.object.1',
        applicant: '@:ET.task_applicant.1',
        category: '@:ET.task_category.1',
        contractor: '@:ET.contractor.1',
        description: 'Опис несправності',
        urgency: '@:ET.task_urgency.1',
        deadline_at: 'Термін',
        submitted_at: 'Дата подачи заявки',
        finished_at: 'Дата виконання заявки',
        created_at: 'Дата створення заявки',
        bill_recipient: 'Рахунок на',
        reason: 'Причина несправності',
        is_paid_work: 'Платна робота',
      },
      FM: {
        create: 'Нова заявка',
        edit: 'Редагування заявки',
        multi_objects: 'Створити на декілька об\'єктах',
      },
      HT: {
        description: 'Опишіть несправність',
        contractor: 'Підрядна компанія по обробки заявок',
      },
      AN: {
        me_applicant: 'Я заявник',
        create_as_test: 'Створити як тестову заявку',
      },
      ST: {
        is_test: 'Це тестова заявка',
        favorite: 'Обране',
        overdue: 'Просрочено',
        my_tasks: 'Мої заявки',
        my_tasks_and_groups: 'Мої заявки + групи',
        result_of_multi_create: 'Результат створення декількох заявок',
      },
    },
    task_applicant: {
      1: 'Заявник',
      2: 'Заявники',
      PL: 'Заявник | Заявники',
    },
    client: {
      1: 'Клієнт',
      2: 'Клієнти',
      PL: 'Клієнт | Клієнти',
    },
    contractor: {
      0: 'Немає підрядників',
      1: 'Підрядчик',
      2: 'Підрядчики',
      PL: 'Підрядник | Підрядники',
    },
    subcontractor: {
      0: 'Немає',
      1: 'Субпідрядник',
      2: 'Субпідрядники',
      PL: 'Субпідрядник | Субпідрядники',
    },
    task_stage: {
      1: 'Етап',
      2: 'Етапи',
      PL: '@:ET.task_stage.1 | @:ET.task_stage.2',
      FD: {
        description: 'Опис етапу',
        responsible: 'Відповідальна компанія за етап',
        subcontractor: 'Виконує субпідрядник',
        deadline_at: '@:ET.task.FD.deadline_at',
        status: 'Статус',
        created_at: 'Етап створено',
        created_by: 'Етап створив',
        started_at: 'Етап розпочато',
        finished_at: 'Етап закінчено',
        finished_by: 'Етап закінчив',
        reason: 'Причина',
      },
      type: {
        CREATED: 'Створено',
        WORKING: 'В роботі',
        REVISING: 'Розглядається',
        EMPLOYEE_SEARCHING: 'Пошук виконавця',
        CONFIRMING: 'На підтвердження',
        SUPPLYING: 'Постачання матеріалів',
        CANCELED: 'Скасовано',
        DONE: 'Виконано',
        CLOSED: 'Закрито',
      },
      status: {
        null: 'Немає статусу',
        DONE: 'Виконано',
        CANCELED: 'Скасовано',
        FAIL: 'Не вирішено',
        TRANSFERRED: 'Переназначено',
        WAITING: 'Заплановано',
        DOING: 'Виконується зараз',
      },
      ST: {
        overdue: 'Етап просрочено',
        finished_at_is_too_late: 'Перевірте чи потрібно оновити дату виконання заявки',
      },
      AN: {
        set_now: 'Оновити дату',
      },
      FM: {
        edit: 'Редагування етапу',
      }
    },
    notification_channel: {
      fcm: 'Повідомлення на пристрій',
      mail: 'Повідомлення на пошту',
      database: 'Повідомлення в базі',
    },
    notification_device: {
      title: 'Сповіщення на цьому пристрої',
      subtitle: 'Вам буде приходити сповіщення на цей пристрій навіть коли додаток закритий',
      AN: {
        prompt: 'Увімкнути сповіщення на цей пристрій?'
      }
    },
    log: {
      name: 'Журнал змін',
      AN: {
        open: 'Відкрити журнал змін',
      },
    },
    task_stage_status: {
      1: 'Статус',
      2: 'Статуси',
      PL: '@:ET.task_stage_status.1 | @:ET.task_stage_status.2',
    },
    task_stage_participant: {
      1: 'Виконавець',
      2: 'Виконавці',
      PL: '@:ET.task_stage_participant.1 | @:ET.task_stage_participant.2',
      FD: {
        type: 'Роль в етапі',
      },
      type: {
        responsible: 'Відповідальний за етап',
        doing: 'Виконавець етапу',
        finished: 'Працював/закінчив етап',
      },
      doing: {
        CREATED: 'Створив | Створили',
        WORKING: 'Виконавець | Виконавці',
        REVISING: 'Розглядує | Розглядують',
        CONFIRMING: 'Розглядує | Розглядують',
        EMPLOYEE_SEARCHING: 'Кандидати',
        CANCELED: 'Скасував | Скасували',
        DONE: 'Закрив | Закрили',
        CLOSED: 'Закрив | Закрили',
      },
      finished: {
        WORKING: 'Виконав | Виконали',
        REVISING: 'Розглянув | Розглянули',
        CONFIRMING: 'Розглянув | Розглянули',
        EMPLOYEE_SEARCHING: 'Взяв | Взяли',
        CANCELED: 'Відкрив | Вікрили',
        DONE: 'Розглянув | Розглянули',
        CLOSED: 'Відкрив | Вікрили',
      },
    },
    task_category: {
      1: 'Система',
      2: 'Системи',
      PL: 'Система | Системи',
    },
    task_activity_code: {
      1: 'Код роботи',
      2: 'Коди робіт',
      PL: '@:ET.task_activity_code.1 | @:ET.task_activity_code.2',
    },
    task_reason: {
      1: 'Причина несправності',
      2: 'Причини несправності',
      PL: '@:ET.task_reason.1 | @:ET.task_reason.2',
    },
    task_urgency: {
      1: 'Терміновість',
      PL: 'Терміновість',
      value: {
        EMERGENCY: 'Аварійна',
        URGENT: 'Термінова',
        NORMAL: 'Планова',
      },
    },
    task_departure: {
      1: 'Виїзд',
      2: 'Виїзди',
      FD: {
        work_started_at: 'Час початку роботи',
        work_finished_at: 'Час закінчення роботи',
        is_billed: 'Є рахунок?',
        is_paid: 'Платна робота?',
        created_at: 'Час реєстрації виїзду',
        updated_at: 'Час оновлення виїзду',
      },
      ST: {
        is_paid_true: 'Платна робота',
        is_paid_false: 'Безплатна робота',
      },
    },
    company: {
      1: 'Компанія',
      2: 'Компанії',
      PL: 'Компанія | Компанії | Компаній',
      FD: {
        name: 'Ім\'я',
        description: '@:TM.description',
        emails: '@:FD.email',
        contacts: '@:FD.contacts',
        contacts_text: 'Контакти (текст)',
        archived: 'Деактивований?',
      },
      FM: {
        edit: 'Редагування компанії',
        create: 'Нова компанія',
      },
      HT: {
        emails: 'Сюди будуть приходити письмо про нові заявки',
      }
    },
    contract: {
      1: 'Контракт',
      2: 'Контракти',
      PL: 'Контракт | Контракти',
      FD: {
        client: 'Клієнт',
        reaction_times: 'Терміни виконання робіт',
        contacts_for: 'Особливі контакти для зв\'язку з {company}',
        type: 'Тип контракту',
        reactions: 'Час реагування',
        contacts_client: 'Контакти Клієнта',
        contacts_contractor: 'Контакти виконавця',
        archived: 'Деактивований?',
        config: {
          reaction_time_emergency: 'Аварія',
          reaction_time_urgent: 'Терміново',
          reaction_time_normal: 'Планова',
        },
      },
      FM: {
        edit: 'Редагування контракту',
        create: 'Створення контракту',
      },
      ER: {
        client_equal_contractor: 'Клієнтом не може бути підрядник',
        contractor_equal_client: 'Підрядником не може бути клієнт',
        reaction_time_more_than_zero: 'Час реагування повинен бути більше за 0',
      },
      HT: {
        type: 'Ким являється підрядник до клієнту',
      },
      actions: {
        add: 'Додати контракт',
      },
      type: {
        contractor: 'Підрядник',
        subcontractor: 'Субпідрядник',
        supplier: 'Поставщик матеріалу',
      },
      categories: {
        client: {
          name: 'Клієнт',
          description: 'Замовник робіт',
          icon: '$client',
          color: 'blue',
        },
        contractor: {
          name: 'Підрядник',
          description: 'Генеральний підрядник у якого замовляються виконання робіт',
          icon: '$contractor',
          color: 'green',
        },
        subcontractor_client: {
          name: 'Замовник',
          description: 'Генеральний підрядник який замовляє виконання робіт (як субпідряднику)',
          icon: '$client',
          color: 'cyan',
        },
        subcontractor_contractor: {
          name: 'Субпідрядник',
          description: 'Субпідрядна компанія яку залучають для виконання певних робіт',
          icon: '$contractor',
          color: 'light-green',
        },
      },
    },
    warehouse: {
      1: 'Склад',
      2: 'Склади',
      PL: '@:ET.warehouse.1 | @:ET.warehouse.2',
      title: {
        my: 'Мої @.lower:ET.warehouse.2',
      },
      type: {
        object: 'Об\'єкт',
        car: 'Машина',
        employee: 'Співробітник',
      },
      FD: {
        type: '@:TM.type',
        name: 'Найменування',
        subject: 'Знаходження',
        company: '@:ET.company.1',
        manager: 'Менеджер',
        archived: 'Архівовано?',
      },
      AN: {
        inventory: 'Інвертарізація',
        takeMaterial: 'Взяти звідси матеріал',
        putMaterial: 'Перенести сюди матеріал',
        writeoff: 'Списати матеріал',
      },
      plural_not_found: '@:ET.warehouse.2 @.lower:ST.not_found',
      FM: {
        create: '@:ST.new @.lower:ET.warehouse.1',
        edit: 'Редагування складу',
      },
      ST: {
        not_exist_my: 'У вас немає персонального складу',
      },
    },
    warehouse_transaction: {
      1: 'Транзакція',
      2: 'Транзакції',
      title: {
        my: 'Мої @.lower:ET.warehouse_transaction.2',
        need_confirm: 'Очікує мого підтвердження',
      },
      type: {
        inventory: 'Інвертарізація',
        transfer: 'Перенесення',
        booking: 'Бронювання',
        writeoff: 'Списання',
      },
      status: {
        ACCEPT: 'Принято',
        PENDING: 'Очікує',
        CANCEL: 'Скасовано',
      },
      status_description: {
        ACCEPT: 'Транзакція підтверджена',
        PENDING: 'Очікує підтвердження',
        CANCEL: 'Скасована',
      },
      FD: {
        from_warehouse: 'Зі складу',
        to_warehouse: 'До складу',
        type: '@:TM.type',
        material: '@:ET.material.1',
        amount: '@:FD.amount',
        employee: 'Ініціатор',
        comment: '@:TM.comment',
        created_at: 'Час створення',
        status: '@:TM.status',
        reviewer: 'Підтвердив',
        reviewer_accept: 'Підтвердив',
        reviewer_cancel: 'Скасував',
        review_at: 'Час підтвердження',
        review_at_accept: 'Час підтвердження',
        review_at_cancel: 'Час скасування',
        review_comment: 'Коментарій підтвердження',
        review_comment_accept: 'Коментарій до підтвердження',
        review_comment_cancel: 'Коментарій до скасуванням',
        used_in_task: 'Використано в заявці',
        used_in_task_stage: 'Використано в етапі',
      },
      FM: {
        review_comment: 'Коментар до підтвердження/скасування',
      },
      plural_not_found: '@:ET.warehouse_transaction.2 @.lower:ST.not_found',
    },
    personal_filter: {
      1: 'Персональний фільтр',
      2: 'Персональні фільтри',
      FD: {
        name: 'Назва фільтру',
      },
      AN: {
        save: 'Зберегти фільтр',
      },
      ST: {
        empty: 'Немає фільтрів',
      }
    },
    warehouse_material: {
      1: '@:ET.material.1',
      2: '@:ET.material.2',
      FD: {
        warehouse: '@:ET.warehouse.1',
        amount: '@:TM.amount',
        stock_minimum: 'Мінімальна Норма',
        stock_average: 'Норма',
      },
      AN: {
        select: 'Вибір матеріалу',
        stock_edit: '@:AN.edit норми',
      },
      ST: {
        available: 'В наявності',
        in_stock: 'В наявності {amount} {unit}',
      },
    },
    material: {
      1: 'Матеріал',
      2: 'Матеріали',
      PL: 'Матеріал | Матеріали',
      FD: {
        search_by_name: 'Пошук по назві матеріалу',
        name: 'Найменування',
        category: '@:ET.material_category.1',
        description: '@:TM.description',
        files: '@:ET.file.2',
        unit: '@:TM.unit',
      },
      ST: {
        not_selected: '@:ET.material.2 @.lower:ST.not_selected',
        available: '@:ST.available матеріалів',
      },
      plural_not_found: '@:ET.material.2 @.lower:ST.not_found',
    },
    material_category: {
      1: 'Категорія',
      2: 'Категорії',
      PL: 'Категорія | Категорії',
      FD: {
        name: 'Назва',
        parent: 'Відноситься до',
      },
      FM: {
        create: '@:ST.creating категорії',
        edit: '@:ST.editing категорії',
        delete: '@:ST.deleting категорії',
      },
      AN: {
        add: 'Додати категорію',
        add_sub: 'Додати підкатегорію',
        edit: 'Редагування категорії',
        delete: 'Видалити категорію',
      },
    },
    worker: {
      1: 'Виконавець',
      2: 'Виконавці',
      PL: '@:ET.worker.1 | @:ET.worker.2',
    },
    employee: {
      1: 'Співробітник',
      2: 'Співробітники',
      PL: 'Співробітник | Співробітники',
      FD: {
        name: 'Ім\'я',
        surname: 'Прізвище',
        company: '@:ET.company.1',
        position: 'Посада',
        phones: 'Номера телефонів',
        email: '@:FD.email',
        archived: 'Деактивований?',
        login: 'Логін',
        password: 'Пароль',
        new_password: 'Новий пароль',
        app_access: 'Доступ до додатків',
      },
      FM: {
        create: 'Створення співробітника',
        edit: 'Редагування співробітника',
        auth: 'Дозволити авторизацію',
      },
      HT: {
        email: 'На цю адресу буде приходити повідомлення',
      },
    },
    car: {
      1: 'Машина',
      2: 'Машини',
    },
    group: {
      1: 'Група співробітників',
      2: 'Групи',
      PL: 'Група | Групи',
      FD: {
        name: 'Назва',
        company: '@:ET.company.1',
        leader: 'Керівник',
        is_workers: 'Група виконавців',
        is_dispatchers: 'Група диспетчерів',
        is_default_dispatchers: 'Головні диспетчера',
        archived: 'Деактивований?',
      },
      FM: {
        create: 'Створення співробітника',
        edit: 'Редагування співробітника',
        auth: 'Дозволити авторизацію',
      },
      HT: {
        is_workers: 'Це робоча група, її можна назначати в "Пошук виконавця"',
        is_dispatchers: 'На цю групу можна передавати на розгляд заявку',
        is_default_dispatchers: 'Ця група буде розглядати нові заявки'
      },
    },
    object: {
      1: 'Об\'єкт',
      2: 'Об\'єкти',
      PL: 'Об\'єкт | Об\'єкти',
      FD: {
        name: 'Назва',
        code: 'Код',
        company: '@:ET.company.1',
        address: '@:TM.address',
        geolocation: '@:TM.geolocation',
        contacts: '@:FD.contacts об\'єкту',
        archived: 'Архівований?'
      },
      FM: {
        edit: 'Редагування об\'єкту',
        create: 'Новий об\'єкт',
        address: 'Адреса об\'єкту',
      },
      HT: {
        company: 'Компанія до якого відноситься об\'єкт',
        address: 'Адреса об\'єкту',
      }
    },
    file: {
      1: 'Файл',
      2: 'Файли',
      AN: {
        add: 'Додати файли',
      },
    },
    report: {
      1: 'Звіт',
      2: 'Звіти',
      all: 'Всі звіти',
      plural_not_found: 'У вас немає звітів',
      FD: {
        fields: 'Поле | Поля',
        period: 'Період',
        name: 'Назва',
        file: 'Файл',
        started_at: 'Розпочато опрацювання',
        finished_at: 'Закінчено опрацювання',
        status: 'Статус',
        created_at: 'Створено',
      },
      status: {
        WAITING: 'В черзі',
        PROCESSING: 'Генерується',
        DONE: 'Готове',
        FAILED: 'Помилка',
        CANCELED: 'Скасовано',
      },
      hint: {
        same_order: 'Колонки будуть виводитися в такому ж порядку',
      },
    },
  },
  PG: {
    name: {
      '/': 'Головна',
      '/login': 'Авторизація',
      '/tasks': '@:ET.task.2',
      '/warehouses/me': 'Мої склади',
      '/warehouses': '@:ET.warehouse.2',
      '/materials': '@:ET.material.2',
      '/materials/categories': 'Категорії матеріалів',
      '/employees': '@:ET.employee.2',
      '/groups': '@:ET.group.2',
      '/objects': '@:ET.object.2',
      '/companies': '@:ET.company.2',
      '/tasks/categories': 'Системи',
      '/tasks/activity_codes': 'Коди робіт',
      '/tasks/departures/is_billed': 'Виставлений рахунок',
      '/reports': '@:ET.report.2',
      '/reports/my': 'Мої звіти',
      '/reports/tasks': '@:ET.task.2',
      '/reports/departures': 'Звіт виїздів',
      '/reports/workload': 'Навантаження',
      '/reports/stats': '@:TM.stats',
      '/reports/departures_price': 'Вартість виїздів',
      '/reports/warehouses_transactions': 'Транзакції матеріалів',
      '/reports/warehouses_materials': 'Матеріали на складах',
      '/reports/accounting_materials_transactions': 'Списання матеріалів з рахунків',
      '/sync/prengi': 'Prengi',
      '/sync/prengi/workers': 'Виконавці',
      '/sync/prengi/objects': 'Об\'єкти',
      '/sync/prengi/categories': 'Системи',
      '/sync/prengi/statuses': 'Статуси',
      '/sync/prengi/log': 'Лог',
      '/accounting': 'Бухгалтерія',
      '/accounting/invoices': '@:ET.accounting_invoice.2',
      '/accounting/materials': '@:ET.accounting_material.2',
      '/paid_incoming_supplier_invoices/import': 'Імпорт оплачених вхідних рахунків постачальників',
      '/accounting/import-tasks-prices': 'Імпорт цін заявок',
    },
  },
  APP: {
    CACHE: {
      name: 'Кеш',
      title: 'Кеш додатку',
      category: {
        app: 'Скрипти, стилі, шрифти',
        files: 'Збережені фотографії',
        other: 'Інше',
        total: 'Всього',
      },
      ST: {
        calculating: 'Розрахунок кеша...',
        deleting: 'Видалення кешу...'
      },
      HT: {
        restartToClearFinish: 'Видалений кеш повністю зникне після перезавантаження додатка',
      },
      AN: {
        clear: 'Видалити кеш',
      }
    },
  },
  AN: {
    close: 'Закрити',
    cancel: 'Скасувати',
    delete: 'Видалити',
    delete_point: 'Видалити точку',
    open: 'Відкрити',
    try_load_again: 'Повторити завантаження',
    mailto: 'Написати',
    mailto_all: '@:AN.mailto всім',
    download: 'Завантажити',
    edit: 'Редагувати',
    add: 'Додати',
    create: 'Створити',
    save: 'Зберегти',
    send: 'Відправити',
    confirm: 'Підтвердити',
    done: 'Готово',
    yes: 'Так',
    no: 'Ні',
    later: 'Пізніше',
    not_now: 'Не зараз',
    on: 'Увімкнути',
    off: 'Вимкнути',
    generate: 'Згенерувати',
  },
  ST: {
    error: 'Помилка',
    nothing_found: 'Нічого @.lower:ST.not_found',
    nothing_found_by_query: 'За запитом "{search}" @.lower:ST.nothing_found',
    archived: 'Архівовано',
    absent: 'Відсутня',
    loading: 'Завантажується...',
    no_choice: 'Немає вибору',
    empty: 'Пусто',
    creating: 'Створення',
    editing: 'Редагування',
    deleting: 'Видалення',
    in_stock: 'В наявності',
    awaiting_confirmation: 'Очікують підтвердження',
    available: 'В наявності',
    selected: 'Обрано',
    not_found: 'Не знайдено',
    new: 'Новий',
    not_selected: 'Не обрано',
    saved_n_records: 'Збережено {n} запис|Збережено {n} записи|Збережено {n} записів',
    saving: 'Збереження...',
    saved: 'Збережено',
    now: 'Зараз',
  },
  FM: {
    required: 'Поле обов\'язкове для заповнення',
    not_required: 'Не обов\'язкове поле',
    min_length: 'Мінімальна кількість символів {n}',
    max_length: 'Максимальная кількість символів {n}',
    no_spaces: 'Не може мати пробіли',
  },
  FD: {
    auth: {
      login: 'Логін',
      password: 'Пароль',
    },
    email: 'Email',
    contacts: 'Контакти',
    search: 'Пошук',
    phone: 'Телефон',
    amount: '@:TM.amount',
    search_by_id: 'Пошук по ID',
  },
  TM: {
    hint: 'Підказки',
    filter: 'Фільтр',
    all: 'Всі',
    main: 'Головне',
    yes: 'Так',
    no: 'Ні',
    by_default: 'За замовчуванням',
    description: 'Опис',
    address: 'Адреса',
    geolocation: 'Місцезнаходження',
    stats: 'Статистика',
    more_filters: 'Додаткові фільтри',
    map: 'Карта',
    stock: 'Запас',
    comment: 'Коментар',
    comments: 'Коментарі',
    status: 'Статус',
    id: 'ID',
    type: 'Тип',
    unit: 'О.В.',
    amount: 'Кількість',
    scanner: 'Сканер',
    result: 'Результат',
    favorite: 'Обране',
  },
  camera: {
    permission_denied: 'Немає дозволу на використання камери.',
  },
  filter: {
    task: {
      stage_participants_employee_group_id: 'Співробітник групи | Співробітники груп',
      stage_participants_group_employee_id: 'Групи співробітника | Групи співробітників',
      search_description: 'Пошук в описі',
      search_comments: 'Пошук в коментарях',
    },
  },
  hint: {
    task_main_filter: 'Це фільтри по основних полях заявки',
    task_search: 'Шукає заявки в яких присутні слова в описі заявки та коментарях. Якщо в полі вказати тільки цифри, тоді буде шукати заявки в яких є відповідні ID системах і додатково шукатиме колекції заявок.',
    task_client: 'Фільтрує заявки в яких вказані ці клієнти',
    task_object: 'Фільтрує заявки де вказані ці об\'єкти',
    task_contractor: 'Фільтрує заявки в яких відповідальний за заявку відповідний підрядник',
    task_category: 'Фільтрує заявки в яких є відповідні системи',
    task_urgency: 'Фільтрує заявки в яких вказані відповідні терміновості',
    task_applicant: 'Фільтрує заявки по вказаним заявникам',
    task_activity_code: 'Фільтрує заявки в яких є вказані відповідні коди робіт',
    task_reason: 'Фільтрує заявки в яких є вказані відповідні причини несправності',
    task_favorite: 'Фільтрує заявки які Ви додали собі в обране',
    task_overdue: 'Фільтрує заявки в яких прострочений термін',
    task_submitted_at: 'Фільтрує заявки які були подані в конкретні дати',
    task_created_at: 'Фільтрує заявки які були створені в конкретні дати',
    task_finished_at: 'Фільтрує заявки які були закінчені в конкретні дати',
    task_deadline_at: 'Фільтрує заявки в яких термін роботи співпадає по конкретним датам',

    task_stage_filter: 'Фільтрує заяви в яких поточний етап співпадає по всім критеріям нище',
    task_stage_type: 'Фільтрує заявки в яких поточний етап співпадає з вказаним типом',
    task_stage_status: 'Фільтрує заявки в яких поточний етап має вказаний статус',
    task_stage_created_by: 'Фільтрує заявки в яких поточний етап створив конкретний співробітник',
    task_stage_responsible: 'Фільтрує заявки в яких за поточний етап відповідальні конкретні компанії',
    task_stage_subcontractor: 'Фільтрує заявки в яких за поточний етап виконують конкретні субпідрядники',
    task_stage_participants_company: 'Фільтрує заявки в яких поточний етап виконують конкретна компанії',
    task_stage_participants_group: 'Фільтрує заявки в яких поточний етап виконують конкретні групи',
    task_stage_participants_group_employee: 'Фільтрує заявки в яких поточний етап виконує групи конкретного співробітника',
    task_stage_participants_employee: 'Фільтрує заявки в яких поточний етап виконують конкретні співробітники',
    task_stage_participants_employee_group: 'Фільтрує заявки в яких поточний етап виконують співробітники конкретних груп',
    task_stage_participants_type: 'Фільтрує заявки в який виконавці поточного етапу мають конкретні ролі в етапі',
    task_stage_overdue: 'Фільтрує заявки в яких поточний етап має дедлайн і він прострочений',
    task_stage_created_at: 'Фільтрує заявки в яких поточний етап створений в конкретні дати',
    task_stage_deadline_at: 'Фільтрує заявки в яких поточний етап має термін виконання по конкретним датам',

    task_stages_filter: 'Фільтрує заяви в яких є етап який підходить по всім нище вказаним критеріям',
    task_stages_type: 'Фільтрує заявки в яких є етап який співпадає з вказаним типом',
    task_stages_status: 'Фільтрує заявки в яких є етап який має статус',
    task_stages_created_by: 'Фільтрує заявки в яких є етап який створив конкретний співробітник',
    task_stages_finished_by: 'Фільтрує заявки в яких є етап який закінчив конкретний співробітник',
    task_stages_responsible: 'Фільтрує заявки в яких є етап за який відповідальний конкретна компанія',
    task_stages_subcontractor: 'Фільтрує заявки в яких є етап який виконує конкретний субпідрядник',
    task_stages_participants_company: 'Фільтрує заявки в яких є етап який виконує конкретна компанії',
    task_stages_participants_group: 'Фільтрує заявки в яких є етап який виконує конкретна група',
    task_stages_participants_group_employee: 'Фільтрує заявки є етап який виконує групи конкретного співробітника',
    task_stages_participants_employee: 'Фільтрує заявки в яких є етап який виконує конкретний співробітник',
    task_stages_participants_employee_group: 'Фільтрує заявки в яких поточний етап виконує співробітники конкретних груп',
    task_stages_participants_type: 'Фільтрує заявки в яких є етап в якому виконавці мають певну роль',
    task_stages_created_at: 'Фільтрує заявки в яких є етап створений в конкретні дати',
    task_stages_finished_at: 'Фільтрує заявки в яких є етап був закінчений в конкретних датах',
  },
  units: {
    days: 'День|Дня|Дней',
    hours: 'Година|Години|Годин',
    minutes: 'Хвилина|Хвилини|Хвилин',
  },
  ER: {
    unsupported_app: 'Цей додаток не підтримує ваші права доступа',
  },
}
