export const FilesDropdown = () => import('../../components/files-dropdown.vue' /* webpackChunkName: "components/files-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const PhonesEditor = () => import('../../components/phones-editor.vue' /* webpackChunkName: "components/phones-editor" */).then(c => wrapFunctional(c.default || c))
export const WindowAdaptation = () => import('../../components/window-adaptation.vue' /* webpackChunkName: "components/window-adaptation" */).then(c => wrapFunctional(c.default || c))
export const AddressAutocomplete = () => import('../../components/address/autocomplete.vue' /* webpackChunkName: "components/address-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const AppCache = () => import('../../components/app/cache.vue' /* webpackChunkName: "components/app-cache" */).then(c => wrapFunctional(c.default || c))
export const CompanyAutocomplete = () => import('../../components/company/autocomplete.vue' /* webpackChunkName: "components/company-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const CompanyDetails = () => import('../../components/company/details.vue' /* webpackChunkName: "components/company-details" */).then(c => wrapFunctional(c.default || c))
export const CompanyEditor = () => import('../../components/company/editor.vue' /* webpackChunkName: "components/company-editor" */).then(c => wrapFunctional(c.default || c))
export const ContactsEditor = () => import('../../components/contacts/editor.vue' /* webpackChunkName: "components/contacts-editor" */).then(c => wrapFunctional(c.default || c))
export const ContactsList = () => import('../../components/contacts/list.vue' /* webpackChunkName: "components/contacts-list" */).then(c => wrapFunctional(c.default || c))
export const ContractEditor = () => import('../../components/contract/editor.vue' /* webpackChunkName: "components/contract-editor" */).then(c => wrapFunctional(c.default || c))
export const DataTableBodyPrependError = () => import('../../components/data-table/body-prepend-error.vue' /* webpackChunkName: "components/data-table-body-prepend-error" */).then(c => wrapFunctional(c.default || c))
export const EmployeeAutocomplete = () => import('../../components/employee/autocomplete.vue' /* webpackChunkName: "components/employee-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const EmployeeDetails = () => import('../../components/employee/details.vue' /* webpackChunkName: "components/employee-details" */).then(c => wrapFunctional(c.default || c))
export const EmployeeEditor = () => import('../../components/employee/editor.vue' /* webpackChunkName: "components/employee-editor" */).then(c => wrapFunctional(c.default || c))
export const EmployeeInfo = () => import('../../components/employee/info.vue' /* webpackChunkName: "components/employee-info" */).then(c => wrapFunctional(c.default || c))
export const FileListEditor = () => import('../../components/file/list-editor.vue' /* webpackChunkName: "components/file-list-editor" */).then(c => wrapFunctional(c.default || c))
export const GroupAutocomplete = () => import('../../components/group/autocomplete.vue' /* webpackChunkName: "components/group-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const GroupEditor = () => import('../../components/group/editor.vue' /* webpackChunkName: "components/group-editor" */).then(c => wrapFunctional(c.default || c))
export const MaterialCharacteristicEditor = () => import('../../components/material/characteristic-editor.vue' /* webpackChunkName: "components/material-characteristic-editor" */).then(c => wrapFunctional(c.default || c))
export const MaterialEditor = () => import('../../components/material/editor.vue' /* webpackChunkName: "components/material-editor" */).then(c => wrapFunctional(c.default || c))
export const MaterialsFetchTable = () => import('../../components/materials/fetch-table.vue' /* webpackChunkName: "components/materials-fetch-table" */).then(c => wrapFunctional(c.default || c))
export const MaterialsFilter = () => import('../../components/materials/filter.vue' /* webpackChunkName: "components/materials-filter" */).then(c => wrapFunctional(c.default || c))
export const MaterialsSelectableAmountTable = () => import('../../components/materials/selectable-amount-table.vue' /* webpackChunkName: "components/materials-selectable-amount-table" */).then(c => wrapFunctional(c.default || c))
export const MaterialsSelectedTable = () => import('../../components/materials/selected-table.vue' /* webpackChunkName: "components/materials-selected-table" */).then(c => wrapFunctional(c.default || c))
export const MaterialsTable = () => import('../../components/materials/table.vue' /* webpackChunkName: "components/materials-table" */).then(c => wrapFunctional(c.default || c))
export const MixinsDialog = () => import('../../components/mixins/dialog.js' /* webpackChunkName: "components/mixins-dialog" */).then(c => wrapFunctional(c.default || c))
export const MixinsDialogCloseBeforeLeave = () => import('../../components/mixins/dialogCloseBeforeLeave.js' /* webpackChunkName: "components/mixins-dialog-close-before-leave" */).then(c => wrapFunctional(c.default || c))
export const MixinsDialogNoCloseBeforeLeave = () => import('../../components/mixins/dialogNoCloseBeforeLeave.js' /* webpackChunkName: "components/mixins-dialog-no-close-before-leave" */).then(c => wrapFunctional(c.default || c))
export const MixinsForm = () => import('../../components/mixins/form.js' /* webpackChunkName: "components/mixins-form" */).then(c => wrapFunctional(c.default || c))
export const MixinsLoadingCancelToken = () => import('../../components/mixins/loadingCancelToken.js' /* webpackChunkName: "components/mixins-loading-cancel-token" */).then(c => wrapFunctional(c.default || c))
export const MixinsLoadingSendingPreset = () => import('../../components/mixins/loadingSendingPreset.js' /* webpackChunkName: "components/mixins-loading-sending-preset" */).then(c => wrapFunctional(c.default || c))
export const MixinsResetData = () => import('../../components/mixins/resetData.js' /* webpackChunkName: "components/mixins-reset-data" */).then(c => wrapFunctional(c.default || c))
export const NotificationsHeader = () => import('../../components/notifications/header.vue' /* webpackChunkName: "components/notifications-header" */).then(c => wrapFunctional(c.default || c))
export const ObjectAutocomplete = () => import('../../components/object/autocomplete.vue' /* webpackChunkName: "components/object-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const ObjectEditor = () => import('../../components/object/editor.vue' /* webpackChunkName: "components/object-editor" */).then(c => wrapFunctional(c.default || c))
export const ParticipantAutocomplete = () => import('../../components/participant/autocomplete.vue' /* webpackChunkName: "components/participant-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const ReportContrainer = () => import('../../components/report/contrainer.vue' /* webpackChunkName: "components/report-contrainer" */).then(c => wrapFunctional(c.default || c))
export const ReportStatus = () => import('../../components/report/status.vue' /* webpackChunkName: "components/report-status" */).then(c => wrapFunctional(c.default || c))
export const ReportTable = () => import('../../components/report/table.vue' /* webpackChunkName: "components/report-table" */).then(c => wrapFunctional(c.default || c))
export const SArchivedIcon = () => import('../../components/s/archived-icon.vue' /* webpackChunkName: "components/s-archived-icon" */).then(c => wrapFunctional(c.default || c))
export const SAutocomplete = () => import('../../components/s/autocomplete.vue' /* webpackChunkName: "components/s-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const SBar = () => import('../../components/s/bar.vue' /* webpackChunkName: "components/s-bar" */).then(c => wrapFunctional(c.default || c))
export const SBoolean = () => import('../../components/s/boolean.vue' /* webpackChunkName: "components/s-boolean" */).then(c => wrapFunctional(c.default || c))
export const SBtn = () => import('../../components/s/btn.vue' /* webpackChunkName: "components/s-btn" */).then(c => wrapFunctional(c.default || c))
export const SConfirmClicker = () => import('../../components/s/confirm-clicker.vue' /* webpackChunkName: "components/s-confirm-clicker" */).then(c => wrapFunctional(c.default || c))
export const SDateInput = () => import('../../components/s/date-input.vue' /* webpackChunkName: "components/s-date-input" */).then(c => wrapFunctional(c.default || c))
export const SDatetimeValue = () => import('../../components/s/datetime-value.vue' /* webpackChunkName: "components/s-datetime-value" */).then(c => wrapFunctional(c.default || c))
export const SErrorPage = () => import('../../components/s/error-page.vue' /* webpackChunkName: "components/s-error-page" */).then(c => wrapFunctional(c.default || c))
export const SError = () => import('../../components/s/error.vue' /* webpackChunkName: "components/s-error" */).then(c => wrapFunctional(c.default || c))
export const SExpand = () => import('../../components/s/expand.vue' /* webpackChunkName: "components/s-expand" */).then(c => wrapFunctional(c.default || c))
export const SFilePreview = () => import('../../components/s/file-preview.vue' /* webpackChunkName: "components/s-file-preview" */).then(c => wrapFunctional(c.default || c))
export const SHeaderSearch = () => import('../../components/s/header-search.vue' /* webpackChunkName: "components/s-header-search" */).then(c => wrapFunctional(c.default || c))
export const SHeader = () => import('../../components/s/header.vue' /* webpackChunkName: "components/s-header" */).then(c => wrapFunctional(c.default || c))
export const SHelpIcon = () => import('../../components/s/help-icon.vue' /* webpackChunkName: "components/s-help-icon" */).then(c => wrapFunctional(c.default || c))
export const SImageViewer = () => import('../../components/s/image-viewer.vue' /* webpackChunkName: "components/s-image-viewer" */).then(c => wrapFunctional(c.default || c))
export const SInputFiles = () => import('../../components/s/input-files.vue' /* webpackChunkName: "components/s-input-files" */).then(c => wrapFunctional(c.default || c))
export const SInternetStatus = () => import('../../components/s/internet-status.vue' /* webpackChunkName: "components/s-internet-status" */).then(c => wrapFunctional(c.default || c))
export const SMap = () => import('../../components/s/map.vue' /* webpackChunkName: "components/s-map" */).then(c => wrapFunctional(c.default || c))
export const SModal = () => import('../../components/s/modal.vue' /* webpackChunkName: "components/s-modal" */).then(c => wrapFunctional(c.default || c))
export const SNavigation = () => import('../../components/s/navigation.vue' /* webpackChunkName: "components/s-navigation" */).then(c => wrapFunctional(c.default || c))
export const SProfile = () => import('../../components/s/profile.vue' /* webpackChunkName: "components/s-profile" */).then(c => wrapFunctional(c.default || c))
export const SQrCode = () => import('../../components/s/qr-code.vue' /* webpackChunkName: "components/s-qr-code" */).then(c => wrapFunctional(c.default || c))
export const SReloadButton = () => import('../../components/s/reload-button.vue' /* webpackChunkName: "components/s-reload-button" */).then(c => wrapFunctional(c.default || c))
export const SScanCode = () => import('../../components/s/scan-code.vue' /* webpackChunkName: "components/s-scan-code" */).then(c => wrapFunctional(c.default || c))
export const SScanner = () => import('../../components/s/scanner.vue' /* webpackChunkName: "components/s-scanner" */).then(c => wrapFunctional(c.default || c))
export const SSelect = () => import('../../components/s/select.vue' /* webpackChunkName: "components/s-select" */).then(c => wrapFunctional(c.default || c))
export const STimeInputs = () => import('../../components/s/time-inputs.vue' /* webpackChunkName: "components/s-time-inputs" */).then(c => wrapFunctional(c.default || c))
export const STooltip = () => import('../../components/s/tooltip.vue' /* webpackChunkName: "components/s-tooltip" */).then(c => wrapFunctional(c.default || c))
export const SUpdateNotification = () => import('../../components/s/update-notification.vue' /* webpackChunkName: "components/s-update-notification" */).then(c => wrapFunctional(c.default || c))
export const SVersion = () => import('../../components/s/version.vue' /* webpackChunkName: "components/s-version" */).then(c => wrapFunctional(c.default || c))
export const StagesStageEditor = () => import('../../components/stages/StageEditor.vue' /* webpackChunkName: "components/stages-stage-editor" */).then(c => wrapFunctional(c.default || c))
export const TaskEditor = () => import('../../components/task/editor.vue' /* webpackChunkName: "components/task-editor" */).then(c => wrapFunctional(c.default || c))
export const TaskLog = () => import('../../components/task/log.vue' /* webpackChunkName: "components/task-log" */).then(c => wrapFunctional(c.default || c))
export const TaskSendLetter = () => import('../../components/task/send-letter.vue' /* webpackChunkName: "components/task-send-letter" */).then(c => wrapFunctional(c.default || c))
export const TaskSentLetters = () => import('../../components/task/sent-letters.vue' /* webpackChunkName: "components/task-sent-letters" */).then(c => wrapFunctional(c.default || c))
export const TaskShare = () => import('../../components/task/share.vue' /* webpackChunkName: "components/task-share" */).then(c => wrapFunctional(c.default || c))
export const TaskUploadFile = () => import('../../components/task/upload-file.vue' /* webpackChunkName: "components/task-upload-file" */).then(c => wrapFunctional(c.default || c))
export const TasksCollections = () => import('../../components/tasks/collections.vue' /* webpackChunkName: "components/tasks-collections" */).then(c => wrapFunctional(c.default || c))
export const TasksFilter = () => import('../../components/tasks/filter.vue' /* webpackChunkName: "components/tasks-filter" */).then(c => wrapFunctional(c.default || c))
export const TasksPagination = () => import('../../components/tasks/pagination.vue' /* webpackChunkName: "components/tasks-pagination" */).then(c => wrapFunctional(c.default || c))
export const TasksPersonalFilters = () => import('../../components/tasks/personal-filters.vue' /* webpackChunkName: "components/tasks-personal-filters" */).then(c => wrapFunctional(c.default || c))
export const TasksReport = () => import('../../components/tasks/report.vue' /* webpackChunkName: "components/tasks-report" */).then(c => wrapFunctional(c.default || c))
export const VBtnTooltip = () => import('../../components/v/btn-tooltip.vue' /* webpackChunkName: "components/v-btn-tooltip" */).then(c => wrapFunctional(c.default || c))
export const VDate = () => import('../../components/v/date.vue' /* webpackChunkName: "components/v-date" */).then(c => wrapFunctional(c.default || c))
export const VDatetime = () => import('../../components/v/datetime.vue' /* webpackChunkName: "components/v-datetime" */).then(c => wrapFunctional(c.default || c))
export const VIconLabel = () => import('../../components/v/icon-label.vue' /* webpackChunkName: "components/v-icon-label" */).then(c => wrapFunctional(c.default || c))
export const VTextFieldWithMenu = () => import('../../components/v/text-field-with-menu.vue' /* webpackChunkName: "components/v-text-field-with-menu" */).then(c => wrapFunctional(c.default || c))
export const VTimePickerList = () => import('../../components/v/time-picker-list.vue' /* webpackChunkName: "components/v-time-picker-list" */).then(c => wrapFunctional(c.default || c))
export const VTime = () => import('../../components/v/time.vue' /* webpackChunkName: "components/v-time" */).then(c => wrapFunctional(c.default || c))
export const VTooltip = () => import('../../components/v/tooltip.vue' /* webpackChunkName: "components/v-tooltip" */).then(c => wrapFunctional(c.default || c))
export const VTreeSelect = () => import('../../components/v/tree-select.js' /* webpackChunkName: "components/v-tree-select" */).then(c => wrapFunctional(c.default || c))
export const WarehouseAutocompleteFetch = () => import('../../components/warehouse/autocomplete-fetch.vue' /* webpackChunkName: "components/warehouse-autocomplete-fetch" */).then(c => wrapFunctional(c.default || c))
export const WarehouseAutocomplete = () => import('../../components/warehouse/autocomplete.vue' /* webpackChunkName: "components/warehouse-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const WarehouseEditor = () => import('../../components/warehouse/editor.vue' /* webpackChunkName: "components/warehouse-editor" */).then(c => wrapFunctional(c.default || c))
export const WarehouseMaterialActions = () => import('../../components/warehouse/material-actions.vue' /* webpackChunkName: "components/warehouse-material-actions" */).then(c => wrapFunctional(c.default || c))
export const WarehouseStockEditor = () => import('../../components/warehouse/stock-editor.vue' /* webpackChunkName: "components/warehouse-stock-editor" */).then(c => wrapFunctional(c.default || c))
export const WarehousesTable = () => import('../../components/warehouses/table.vue' /* webpackChunkName: "components/warehouses-table" */).then(c => wrapFunctional(c.default || c))
export const AccountingInvoicesFetchTable = () => import('../../components/accounting/invoices/fetch-table.vue' /* webpackChunkName: "components/accounting-invoices-fetch-table" */).then(c => wrapFunctional(c.default || c))
export const AccountingInvoicesImport = () => import('../../components/accounting/invoices/import.vue' /* webpackChunkName: "components/accounting-invoices-import" */).then(c => wrapFunctional(c.default || c))
export const AccountingInvoicesTable = () => import('../../components/accounting/invoices/table.vue' /* webpackChunkName: "components/accounting-invoices-table" */).then(c => wrapFunctional(c.default || c))
export const AccountingMaterialsFetchTable = () => import('../../components/accounting/materials/fetch-table.vue' /* webpackChunkName: "components/accounting-materials-fetch-table" */).then(c => wrapFunctional(c.default || c))
export const AccountingMaterialsInventory = () => import('../../components/accounting/materials/inventory.vue' /* webpackChunkName: "components/accounting-materials-inventory" */).then(c => wrapFunctional(c.default || c))
export const AccountingMaterialsTable = () => import('../../components/accounting/materials/table.vue' /* webpackChunkName: "components/accounting-materials-table" */).then(c => wrapFunctional(c.default || c))
export const DataTableItemEmployee = () => import('../../components/data-table/item/employee.vue' /* webpackChunkName: "components/data-table-item-employee" */).then(c => wrapFunctional(c.default || c))
export const FormFileItem = () => import('../../components/form/file/item.vue' /* webpackChunkName: "components/form-file-item" */).then(c => wrapFunctional(c.default || c))
export const MaterialItemImage = () => import('../../components/material/item/image.vue' /* webpackChunkName: "components/material-item-image" */).then(c => wrapFunctional(c.default || c))
export const SMapIcon = () => import('../../components/s/map/icon.vue' /* webpackChunkName: "components/s-map-icon" */).then(c => wrapFunctional(c.default || c))
export const SMapMarker = () => import('../../components/s/map/marker.js' /* webpackChunkName: "components/s-map-marker" */).then(c => wrapFunctional(c.default || c))
export const SMapTooltip = () => import('../../components/s/map/tooltip.js' /* webpackChunkName: "components/s-map-tooltip" */).then(c => wrapFunctional(c.default || c))
export const TaskActionCancel = () => import('../../components/task/action/cancel.vue' /* webpackChunkName: "components/task-action-cancel" */).then(c => wrapFunctional(c.default || c))
export const TaskActionChangeContractor = () => import('../../components/task/action/change-contractor.vue' /* webpackChunkName: "components/task-action-change-contractor" */).then(c => wrapFunctional(c.default || c))
export const TaskActionClose = () => import('../../components/task/action/close.vue' /* webpackChunkName: "components/task-action-close" */).then(c => wrapFunctional(c.default || c))
export const TaskActionDone = () => import('../../components/task/action/done.vue' /* webpackChunkName: "components/task-action-done" */).then(c => wrapFunctional(c.default || c))
export const TaskActionOpenAgain = () => import('../../components/task/action/open-again.vue' /* webpackChunkName: "components/task-action-open-again" */).then(c => wrapFunctional(c.default || c))
export const TaskActionRevising = () => import('../../components/task/action/revising.vue' /* webpackChunkName: "components/task-action-revising" */).then(c => wrapFunctional(c.default || c))
export const TaskActionSearchEmployee = () => import('../../components/task/action/search-employee.vue' /* webpackChunkName: "components/task-action-search-employee" */).then(c => wrapFunctional(c.default || c))
export const TaskActionStartWork = () => import('../../components/task/action/start-work.vue' /* webpackChunkName: "components/task-action-start-work" */).then(c => wrapFunctional(c.default || c))
export const TaskActionTakeWork = () => import('../../components/task/action/take-work.vue' /* webpackChunkName: "components/task-action-take-work" */).then(c => wrapFunctional(c.default || c))
export const TaskActionTransferSubcontractor = () => import('../../components/task/action/transfer-subcontractor.vue' /* webpackChunkName: "components/task-action-transfer-subcontractor" */).then(c => wrapFunctional(c.default || c))
export const TaskActionWorkDone = () => import('../../components/task/action/work-done.vue' /* webpackChunkName: "components/task-action-work-done" */).then(c => wrapFunctional(c.default || c))
export const TaskActionWorking = () => import('../../components/task/action/working.vue' /* webpackChunkName: "components/task-action-working" */).then(c => wrapFunctional(c.default || c))
export const TaskActivityCodeSelect = () => import('../../components/task/activity-code/select.vue' /* webpackChunkName: "components/task-activity-code-select" */).then(c => wrapFunctional(c.default || c))
export const TaskApplicantAutocomplete = () => import('../../components/task/applicant/autocomplete.vue' /* webpackChunkName: "components/task-applicant-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const TaskApplicantEditor = () => import('../../components/task/applicant/editor.vue' /* webpackChunkName: "components/task-applicant-editor" */).then(c => wrapFunctional(c.default || c))
export const TaskCategorySelect = () => import('../../components/task/category/select.vue' /* webpackChunkName: "components/task-category-select" */).then(c => wrapFunctional(c.default || c))
export const TaskCollectionAttachTask = () => import('../../components/task/collection/attach-task.vue' /* webpackChunkName: "components/task-collection-attach-task" */).then(c => wrapFunctional(c.default || c))
export const TaskCollectionEditor = () => import('../../components/task/collection/editor.vue' /* webpackChunkName: "components/task-collection-editor" */).then(c => wrapFunctional(c.default || c))
export const TaskDepartureCreate = () => import('../../components/task/departure/create.vue' /* webpackChunkName: "components/task-departure-create" */).then(c => wrapFunctional(c.default || c))
export const TaskDepartureCrossingError = () => import('../../components/task/departure/crossing-error.vue' /* webpackChunkName: "components/task-departure-crossing-error" */).then(c => wrapFunctional(c.default || c))
export const TaskDepartureEdit = () => import('../../components/task/departure/edit.vue' /* webpackChunkName: "components/task-departure-edit" */).then(c => wrapFunctional(c.default || c))
export const TaskDepartureTimeSelect = () => import('../../components/task/departure/time-select.vue' /* webpackChunkName: "components/task-departure-time-select" */).then(c => wrapFunctional(c.default || c))
export const TaskParticipantDetails = () => import('../../components/task/participant/details.vue' /* webpackChunkName: "components/task-participant-details" */).then(c => wrapFunctional(c.default || c))
export const TaskParticipantItem = () => import('../../components/task/participant/item.vue' /* webpackChunkName: "components/task-participant-item" */).then(c => wrapFunctional(c.default || c))
export const TaskParticipantResponsibleMark = () => import('../../components/task/participant/responsible-mark.vue' /* webpackChunkName: "components/task-participant-responsible-mark" */).then(c => wrapFunctional(c.default || c))
export const TaskReasonSelect = () => import('../../components/task/reason/select.vue' /* webpackChunkName: "components/task-reason-select" */).then(c => wrapFunctional(c.default || c))
export const TaskStageDetails = () => import('../../components/task/stage/details.vue' /* webpackChunkName: "components/task-stage-details" */).then(c => wrapFunctional(c.default || c))
export const TaskStageEditor = () => import('../../components/task/stage/editor.vue' /* webpackChunkName: "components/task-stage-editor" */).then(c => wrapFunctional(c.default || c))
export const TaskViewAccountingInvoices = () => import('../../components/task/view/accounting-invoices.vue' /* webpackChunkName: "components/task-view-accounting-invoices" */).then(c => wrapFunctional(c.default || c))
export const TaskViewAllFiles = () => import('../../components/task/view/all-files.vue' /* webpackChunkName: "components/task-view-all-files" */).then(c => wrapFunctional(c.default || c))
export const TaskViewApplicantInfo = () => import('../../components/task/view/applicant-info.vue' /* webpackChunkName: "components/task-view-applicant-info" */).then(c => wrapFunctional(c.default || c))
export const TaskViewCollections = () => import('../../components/task/view/collections.vue' /* webpackChunkName: "components/task-view-collections" */).then(c => wrapFunctional(c.default || c))
export const TaskViewCommentsNew = () => import('../../components/task/view/comments-new.vue' /* webpackChunkName: "components/task-view-comments-new" */).then(c => wrapFunctional(c.default || c))
export const TaskViewComments = () => import('../../components/task/view/comments.vue' /* webpackChunkName: "components/task-view-comments" */).then(c => wrapFunctional(c.default || c))
export const TaskViewData = () => import('../../components/task/view/data.vue' /* webpackChunkName: "components/task-view-data" */).then(c => wrapFunctional(c.default || c))
export const TaskViewDepartures = () => import('../../components/task/view/departures.vue' /* webpackChunkName: "components/task-view-departures" */).then(c => wrapFunctional(c.default || c))
export const TaskViewStage = () => import('../../components/task/view/stage.vue' /* webpackChunkName: "components/task-view-stage" */).then(c => wrapFunctional(c.default || c))
export const TaskViewStagesItem = () => import('../../components/task/view/stages-item.vue' /* webpackChunkName: "components/task-view-stages-item" */).then(c => wrapFunctional(c.default || c))
export const TaskViewStages = () => import('../../components/task/view/stages.vue' /* webpackChunkName: "components/task-view-stages" */).then(c => wrapFunctional(c.default || c))
export const TaskViewTools = () => import('../../components/task/view/tools.vue' /* webpackChunkName: "components/task-view-tools" */).then(c => wrapFunctional(c.default || c))
export const TaskViewViewers = () => import('../../components/task/view/viewers.vue' /* webpackChunkName: "components/task-view-viewers" */).then(c => wrapFunctional(c.default || c))
export const TasksFilterBar = () => import('../../components/tasks/filter/bar.vue' /* webpackChunkName: "components/tasks-filter-bar" */).then(c => wrapFunctional(c.default || c))
export const TasksFilterChipAdditional = () => import('../../components/tasks/filter/chip-additional.vue' /* webpackChunkName: "components/tasks-filter-chip-additional" */).then(c => wrapFunctional(c.default || c))
export const TasksFilterChipAll = () => import('../../components/tasks/filter/chip-all.vue' /* webpackChunkName: "components/tasks-filter-chip-all" */).then(c => wrapFunctional(c.default || c))
export const TasksFilterChipClient = () => import('../../components/tasks/filter/chip-client.vue' /* webpackChunkName: "components/tasks-filter-chip-client" */).then(c => wrapFunctional(c.default || c))
export const TasksFilterChipStatus = () => import('../../components/tasks/filter/chip-status.vue' /* webpackChunkName: "components/tasks-filter-chip-status" */).then(c => wrapFunctional(c.default || c))
export const TasksFilterChipSubcontractor = () => import('../../components/tasks/filter/chip-subcontractor.vue' /* webpackChunkName: "components/tasks-filter-chip-subcontractor" */).then(c => wrapFunctional(c.default || c))
export const TasksListItemChip = () => import('../../components/tasks/list/item-chip.vue' /* webpackChunkName: "components/tasks-list-item-chip" */).then(c => wrapFunctional(c.default || c))
export const TasksListItemComments = () => import('../../components/tasks/list/item-comments.vue' /* webpackChunkName: "components/tasks-list-item-comments" */).then(c => wrapFunctional(c.default || c))
export const TasksListItemData = () => import('../../components/tasks/list/item-data.vue' /* webpackChunkName: "components/tasks-list-item-data" */).then(c => wrapFunctional(c.default || c))
export const TasksListItemDescription = () => import('../../components/tasks/list/item-description.vue' /* webpackChunkName: "components/tasks-list-item-description" */).then(c => wrapFunctional(c.default || c))
export const TasksListItemStage = () => import('../../components/tasks/list/item-stage.vue' /* webpackChunkName: "components/tasks-list-item-stage" */).then(c => wrapFunctional(c.default || c))
export const TasksListItemWorkers = () => import('../../components/tasks/list/item-workers.vue' /* webpackChunkName: "components/tasks-list-item-workers" */).then(c => wrapFunctional(c.default || c))
export const TasksListItem = () => import('../../components/tasks/list/item.vue' /* webpackChunkName: "components/tasks-list-item" */).then(c => wrapFunctional(c.default || c))
export const WarehouseTransactionDetails = () => import('../../components/warehouse/transaction/details.vue' /* webpackChunkName: "components/warehouse-transaction-details" */).then(c => wrapFunctional(c.default || c))
export const WarehouseTransactionInventory = () => import('../../components/warehouse/transaction/inventory.vue' /* webpackChunkName: "components/warehouse-transaction-inventory" */).then(c => wrapFunctional(c.default || c))
export const WarehouseTransactionScanMaterial = () => import('../../components/warehouse/transaction/scan-material.vue' /* webpackChunkName: "components/warehouse-transaction-scan-material" */).then(c => wrapFunctional(c.default || c))
export const WarehouseTransactionSelectedMaterials = () => import('../../components/warehouse/transaction/selected-materials.vue' /* webpackChunkName: "components/warehouse-transaction-selected-materials" */).then(c => wrapFunctional(c.default || c))
export const WarehouseTransactionStatusIcon = () => import('../../components/warehouse/transaction/status-icon.vue' /* webpackChunkName: "components/warehouse-transaction-status-icon" */).then(c => wrapFunctional(c.default || c))
export const WarehouseTransactionTransfer = () => import('../../components/warehouse/transaction/transfer.vue' /* webpackChunkName: "components/warehouse-transaction-transfer" */).then(c => wrapFunctional(c.default || c))
export const WarehouseTransactionWriteoff = () => import('../../components/warehouse/transaction/writeoff.vue' /* webpackChunkName: "components/warehouse-transaction-writeoff" */).then(c => wrapFunctional(c.default || c))
export const WarehousesItemSubject = () => import('../../components/warehouses/item/subject.vue' /* webpackChunkName: "components/warehouses-item-subject" */).then(c => wrapFunctional(c.default || c))
export const WarehousesMaterialsFetchTable = () => import('../../components/warehouses/materials/fetch-table.vue' /* webpackChunkName: "components/warehouses-materials-fetch-table" */).then(c => wrapFunctional(c.default || c))
export const WarehousesMaterialsSelectableAmountTable = () => import('../../components/warehouses/materials/selectable-amount-table.vue' /* webpackChunkName: "components/warehouses-materials-selectable-amount-table" */).then(c => wrapFunctional(c.default || c))
export const WarehousesMaterialsTable = () => import('../../components/warehouses/materials/table.vue' /* webpackChunkName: "components/warehouses-materials-table" */).then(c => wrapFunctional(c.default || c))
export const WarehousesTransactionsFetchTable = () => import('../../components/warehouses/transactions/fetch-table.vue' /* webpackChunkName: "components/warehouses-transactions-fetch-table" */).then(c => wrapFunctional(c.default || c))
export const WarehousesTransactionsTable = () => import('../../components/warehouses/transactions/table.vue' /* webpackChunkName: "components/warehouses-transactions-table" */).then(c => wrapFunctional(c.default || c))
export const TaskActionFormActivityCode = () => import('../../components/task/action/form/activityCode.vue' /* webpackChunkName: "components/task-action-form-activity-code" */).then(c => wrapFunctional(c.default || c))
export const TaskActionFormCancel = () => import('../../components/task/action/form/cancel.vue' /* webpackChunkName: "components/task-action-form-cancel" */).then(c => wrapFunctional(c.default || c))
export const TaskActionFormCategory = () => import('../../components/task/action/form/category.vue' /* webpackChunkName: "components/task-action-form-category" */).then(c => wrapFunctional(c.default || c))
export const TaskActionFormChangeContractor = () => import('../../components/task/action/form/changeContractor.vue' /* webpackChunkName: "components/task-action-form-change-contractor" */).then(c => wrapFunctional(c.default || c))
export const TaskActionFormClose = () => import('../../components/task/action/form/close.vue' /* webpackChunkName: "components/task-action-form-close" */).then(c => wrapFunctional(c.default || c))
export const TaskActionFormDefault = () => import('../../components/task/action/form/default.vue' /* webpackChunkName: "components/task-action-form-default" */).then(c => wrapFunctional(c.default || c))
export const TaskActionFormDepartures = () => import('../../components/task/action/form/departures.vue' /* webpackChunkName: "components/task-action-form-departures" */).then(c => wrapFunctional(c.default || c))
export const TaskActionFormEmails = () => import('../../components/task/action/form/emails.vue' /* webpackChunkName: "components/task-action-form-emails" */).then(c => wrapFunctional(c.default || c))
export const TaskActionFormFiles = () => import('../../components/task/action/form/files.vue' /* webpackChunkName: "components/task-action-form-files" */).then(c => wrapFunctional(c.default || c))
export const TaskActionForm = () => import('../../components/task/action/form/form.js' /* webpackChunkName: "components/task-action-form" */).then(c => wrapFunctional(c.default || c))
export const TaskActionFormOpenAgain = () => import('../../components/task/action/form/openAgain.vue' /* webpackChunkName: "components/task-action-form-open-again" */).then(c => wrapFunctional(c.default || c))
export const TaskActionFormRevising = () => import('../../components/task/action/form/revising.vue' /* webpackChunkName: "components/task-action-form-revising" */).then(c => wrapFunctional(c.default || c))
export const TaskActionFormSearchEmployee = () => import('../../components/task/action/form/searchEmployee.vue' /* webpackChunkName: "components/task-action-form-search-employee" */).then(c => wrapFunctional(c.default || c))
export const TaskActionFormStartWork = () => import('../../components/task/action/form/startWork.vue' /* webpackChunkName: "components/task-action-form-start-work" */).then(c => wrapFunctional(c.default || c))
export const TaskActionFormTransferSubcontractor = () => import('../../components/task/action/form/transferSubcontractor.vue' /* webpackChunkName: "components/task-action-form-transfer-subcontractor" */).then(c => wrapFunctional(c.default || c))
export const TaskActionFormUsedMaterials = () => import('../../components/task/action/form/usedMaterials.vue' /* webpackChunkName: "components/task-action-form-used-materials" */).then(c => wrapFunctional(c.default || c))
export const TaskActionFormWorkDone = () => import('../../components/task/action/form/workDone.vue' /* webpackChunkName: "components/task-action-form-work-done" */).then(c => wrapFunctional(c.default || c))
export const TaskActionFormWorking = () => import('../../components/task/action/form/working.vue' /* webpackChunkName: "components/task-action-form-working" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
