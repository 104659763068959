import Vue from 'vue'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

const iconController = new (function () {
    this.componentSymbols = new Set()
    this.usedIcons = new Map()
    this.arrayToKey = (arr) => `${arr[0]}|${arr[1]}`
    this.keyToArray = (str) => str.split('|')
    let updateTimer = null
    const update = () => {
        if (updateTimer) return;
        updateTimer = true
        Vue.nextTick(() => {
            updateTimer = false
            this.componentSymbols.forEach(comp => comp.$forceUpdate())
        })
    }
    this.newIconHandler = (icon) => {
        const key = this.arrayToKey(icon)
        if (!this.usedIcons.has(key)) {
            this.usedIcons.set(key, icon)
            update()
        }
    }
})();
Vue.component('font-awesome-icon', {
    functional: true,
    props: FontAwesomeIcon.props,
    render(h, context) {
        iconController.newIconHandler(context.props.icon)
        return h('svg', {
            ...context.data,
            class: {
                ...context.data.class,
                'svg-inline--fa': true,
            },
        }, [
            h('use', {
                attrs: {
                    'xlink:href': `#${context.props.icon[0]}-fa-${context.props.icon[1]}`
                }
            })
        ])
    }
})
Vue.component('font-awesome-icon-symbols', {
    created() {
        iconController.componentSymbols.add(this)
    },
    beforeDestroy() {
        iconController.componentSymbols.delete(this)
    },
    render(createElement) {
        return createElement('div', {
            style: {
                display: 'none'
            }
        }, [
            Array.from(iconController.usedIcons, ([key, arr]) => {
                return createElement(FontAwesomeIcon, {
                    props: {
                        icon: arr,
                        symbol: true,
                    },
                    key
                })
            })
        ])
    }
})

import {
	faUser as farUser,
	faCaretDown as farCaretDown,
	faClipboardListCheck as farClipboardListCheck,
	faUserHardHat as farUserHardHat,
	faUsersClass as farUsersClass,
	faUserTie as farUserTie,
	faCity as farCity,
	faHardHat as farHardHat,
	faPhoneVolume as farPhoneVolume,
	faCarBuilding as farCarBuilding,
	faFileSignature as farFileSignature,
	faBox as farBox,
	faShapes as farShapes,
	faShoppingCart as farShoppingCart,
	faBoneBreak as farBoneBreak,
	faCommentExclamation as farCommentExclamation,
	faWarehouseAlt as farWarehouseAlt,
	faPersonCarry as farPersonCarry,
	faPeopleCarry as farPeopleCarry,
	faCar as farCar,
	faCalendarStar as farCalendarStar,
	faFileInvoice as farFileInvoice,
	faFileInvoiceDollar as farFileInvoiceDollar,
	faTools as farTools,
	faShippingFast as farShippingFast,
	faFolderTree as farFolderTree,
	faFolder as farFolder,
	faHourglass as farHourglass,
	faCodeMerge as farCodeMerge,
	faStream as farStream,
	faBadge as farBadge,
	faCheckCircle as farCheckCircle,
	faTimesCircle as farTimesCircle,
	faExclamationCircle as farExclamationCircle,
	faPlayCircle as farPlayCircle,
	faPauseCircle as farPauseCircle,
	faPlus as farPlus,
	faUserHeadset as farUserHeadset,
	faSearch as farSearch,
	faCheckDouble as farCheckDouble,
	faTruck as farTruck,
	faBan as farBan,
	faCheck as farCheck,
	faEclipseAlt as farEclipseAlt,
	faSun as farSun,
	faEclipse as farEclipse,
	faUndoAlt as farUndoAlt,
	faCalendarPlus as farCalendarPlus,
	faCalendarExclamation as farCalendarExclamation,
	faCommentAltLines as farCommentAltLines,
	faMapMarkerAlt as farMapMarkerAlt,
	faEnvelope as farEnvelope,
	faPhoneAlt as farPhoneAlt,
	faAddressCard as farAddressCard,
	faClock as farClock,
	faPaperPlane as farPaperPlane,
	faPen as farPen,
	faEye as farEye,
	faEyeSlash as farEyeSlash,
	faTag as farTag,
	faArchive as farArchive,
	faCrown as farCrown,
	faQuestionCircle as farQuestionCircle,
	faSunset as farSunset,
	faSunrise as farSunrise,
	faFilter as farFilter,
	faPhoneLaptop as farPhoneLaptop,
	faFlag as farFlag,
	faFlagCheckered as farFlagCheckered,
	faLockAlt as farLockAlt,
	faLockOpenAlt as farLockOpenAlt,
	faCalendarDay as farCalendarDay,
	faHandPeace as farHandPeace,
	faHandPointLeft as farHandPointLeft,
	faExternalLink as farExternalLink,
	faBusinessTime as farBusinessTime,
	faShippingTimed as farShippingTimed,
	faPlusCircle as farPlusCircle,
	faShareAlt as farShareAlt,
	faStar as farStar,
	faEllipsisHAlt as farEllipsisHAlt,
	faArrowsAltH as farArrowsAltH,
	faLifeRing as farLifeRing,
	faShieldAlt as farShieldAlt,
	faBellOn as farBellOn,
	faSync as farSync,
	faStepBackward as farStepBackward,
	faStepForward as farStepForward,
	faArrowsAltV as farArrowsAltV,
	faTimes as farTimes,
	faInfoCircle as farInfoCircle,
	faExclamation as farExclamation,
	faExclamationTriangle as farExclamationTriangle,
	faChevronLeft as farChevronLeft,
	faChevronRight as farChevronRight,
	faCheckSquare as farCheckSquare,
	faSquare as farSquare,
	faMinusSquare as farMinusSquare,
	faCircle as farCircle,
	faSortUp as farSortUp,
	faChevronDown as farChevronDown,
	faExpand as farExpand,
	faBars as farBars,
	faSortAmountUp as farSortAmountUp,
	faSortAmountDown as farSortAmountDown,
	faBarcodeRead as farBarcodeRead,
	faDotCircle as farDotCircle,
	faLightbulb as farLightbulb,
	faArrowFromLeft as farArrowFromLeft,
	faArrowToRight as farArrowToRight,
	faCalculator as farCalculator,
	faUsdCircle as farUsdCircle,
	faBookmark as farBookmark,
	faBell as farBell,
	faCopy as farCopy,
	faHistory as farHistory,
	faPlay as farPlay,
	faCode as farCode,
	faGlobe as farGlobe,
	faHashtag as farHashtag,
	faComment as farComment,
	faComments as farComments,
	faSave as farSave,
	faPause as farPause,
	faFolderPlus as farFolderPlus,
	faImage as farImage,
	faExchange as farExchange,
	faCropAlt as farCropAlt,
	faFile as farFile,
	faFileArchive as farFileArchive,
	faFileAudio as farFileAudio,
	faFileExcel as farFileExcel,
	faFileImage as farFileImage,
	faFileMusic as farFileMusic,
	faFilePdf as farFilePdf,
	faFilePowerpoint as farFilePowerpoint,
	faFileVideo as farFileVideo,
	faFileWord as farFileWord,
	faFilePlus as farFilePlus,
	faUpload as farUpload,
	faDownload as farDownload,
	faMinus as farMinus,
	faPaste as farPaste,
	faCalendar as farCalendar,
	faCalendarAlt as farCalendarAlt,
	faCalendarWeek as farCalendarWeek,
	faList as farList,
	faTable as farTable,
	faText as farText,
	faCartPlus as farCartPlus,
	faCartArrowDown as farCartArrowDown,
	faQrcode as farQrcode,
	faWifiSlash as farWifiSlash,
	faEquals as farEquals,
	faNotEqual as farNotEqual
} from '@fortawesome/pro-regular-svg-icons'
import {
	faStripeS as fabStripeS
} from '@fortawesome/free-brands-svg-icons'
import {
	faStar as fasStar,
	faStarHalfAlt as fasStarHalfAlt,
	faLightbulbOn as fasLightbulbOn
} from '@fortawesome/pro-solid-svg-icons'
import {
	faSortAlt as fadSortAlt
} from '@fortawesome/pro-duotone-svg-icons'

    library.add(
        farUser,
	farCaretDown,
	farClipboardListCheck,
	farUserHardHat,
	farUsersClass,
	farUserTie,
	farCity,
	farHardHat,
	farPhoneVolume,
	farCarBuilding,
	farFileSignature,
	farBox,
	farShapes,
	farShoppingCart,
	farBoneBreak,
	farCommentExclamation,
	farWarehouseAlt,
	farPersonCarry,
	farPeopleCarry,
	farCar,
	fabStripeS,
	farCalendarStar,
	farFileInvoice,
	farFileInvoiceDollar,
	farTools,
	farShippingFast,
	farFolderTree,
	farFolder,
	farHourglass,
	farCodeMerge,
	farStream,
	farBadge,
	farCheckCircle,
	farTimesCircle,
	farExclamationCircle,
	farPlayCircle,
	farPauseCircle,
	farPlus,
	farUserHeadset,
	farSearch,
	farCheckDouble,
	farTruck,
	farBan,
	farCheck,
	farEclipseAlt,
	farSun,
	farEclipse,
	farUndoAlt,
	farCalendarPlus,
	farCalendarExclamation,
	farCommentAltLines,
	farMapMarkerAlt,
	farEnvelope,
	farPhoneAlt,
	farAddressCard,
	farClock,
	farPaperPlane,
	farPen,
	farEye,
	farEyeSlash,
	farTag,
	farArchive,
	farCrown,
	farQuestionCircle,
	farSunset,
	farSunrise,
	farFilter,
	farPhoneLaptop,
	farFlag,
	farFlagCheckered,
	farLockAlt,
	farLockOpenAlt,
	farCalendarDay,
	farHandPeace,
	farHandPointLeft,
	farExternalLink,
	farBusinessTime,
	farShippingTimed,
	farPlusCircle,
	farShareAlt,
	fasStar,
	farStar,
	farEllipsisHAlt,
	farArrowsAltH,
	fadSortAlt,
	farLifeRing,
	farShieldAlt,
	farBellOn,
	farSync,
	farStepBackward,
	farStepForward,
	farArrowsAltV,
	farTimes,
	farInfoCircle,
	farExclamation,
	farExclamationTriangle,
	farChevronLeft,
	farChevronRight,
	farCheckSquare,
	farSquare,
	farMinusSquare,
	farCircle,
	farSortUp,
	farChevronDown,
	farExpand,
	farBars,
	fasStarHalfAlt,
	farSortAmountUp,
	farSortAmountDown,
	farBarcodeRead,
	farDotCircle,
	farLightbulb,
	fasLightbulbOn,
	farArrowFromLeft,
	farArrowToRight,
	farCalculator,
	farUsdCircle,
	farBookmark,
	farBell,
	farCopy,
	farHistory,
	farPlay,
	farCode,
	farGlobe,
	farHashtag,
	farComment,
	farComments,
	farSave,
	farPause,
	farFolderPlus,
	farImage,
	farExchange,
	farCropAlt,
	farFile,
	farFileArchive,
	farFileAudio,
	farFileExcel,
	farFileImage,
	farFileMusic,
	farFilePdf,
	farFilePowerpoint,
	farFileVideo,
	farFileWord,
	farFilePlus,
	farUpload,
	farDownload,
	farMinus,
	farPaste,
	farCalendar,
	farCalendarAlt,
	farCalendarWeek,
	farList,
	farTable,
	farText,
	farCartPlus,
	farCartArrowDown,
	farQrcode,
	farWifiSlash,
	farEquals,
	farNotEqual
)
